<template>
  <el-main>
    <el-form class="el-form-search" label-width="120px">
      <el-form-item label="主理人名称：">
        <el-input size="small" v-model="searchForm.nickname" placeholder="请输入主理人名称"></el-input>
      </el-form-item>
      <el-form-item label="主理人电话：">
        <el-input size="small" v-model="searchForm.mobile" placeholder="请输入主理人电话"></el-input>
      </el-form-item>
      <el-form-item label="申请时间：">
        <el-date-picker size="small" v-model="searchForm.create_time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="20px">
        <el-button type="primary" size="small" @click="getList(1)">搜索</el-button>
        <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column label="主理人信息" align="center">
        <template v-slot="{ row }">
          <UserInfo :avatar="row.avatar" :name="row.nickname"></UserInfo>
        </template>
      </el-table-column>
      <el-table-column label="主理人等级" align="center">
        <template v-slot="{ row }">
          {{ level_id_hash[row.level_id] }}
        </template>
      </el-table-column>
      <el-table-column label="带队类型" align="center">
        <template v-slot="{ row }">
          {{ row.classify_name.join(',') }}
        </template>
      </el-table-column>
      <el-table-column label="联系人姓名" align="center">
        <template v-slot="{ row }">
          {{ row.contact_name || '--' }}
        </template>
      </el-table-column>
      <el-table-column label="联系电话" align="center">
        <template v-slot="{ row }">
          {{ row.hoster_phone || row.user_info.mobile }}
        </template>
      </el-table-column>
      <el-table-column prop="settle_rate" label="自定义组局扣除比例" align="center"></el-table-column>
      <el-table-column prop="meal_settle_rate" label="套餐组局扣除比例" align="center"></el-table-column>
      <el-table-column prop="hoster_avg_price" label="主理人带队费" align="center"></el-table-column>
      <el-table-column label="创建时间" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
      </el-table-column>
      <el-table-column label="是否支付" align="center">
        <template v-slot="{ row }">
          <div v-if="!row.is_need_pay">--</div>
          <div v-else-if="row.is_pay">已支付</div>
          <div v-else>未支付</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right" width="200">
        <template v-slot="{ row }">
          <div v-if="row.status == 0">
            <el-button type="text" @click="toExamineFn(row)">审核</el-button>
          </div>
          <div v-if="row.status == -1">
            <el-link type="danger">已驳回</el-link>
          </div>
          <el-button @click="showHosterConfig(row)" type="text">主理人配置</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
    <!-- 设置银行卡弹窗 -->
    <el-dialog title="设置银行卡" :visible.sync="showSetBank" width="750px">
      <el-form label-width="130px">
        <el-form-item label="持卡人真实姓名：">
          <el-input v-model="bankForm.bank_name"></el-input>
        </el-form-item>
        <el-form-item label="银行卡号：">
          <el-input v-model="bankForm.bank_card"></el-input>
        </el-form-item>
        <el-form-item label="开户支行：">
          <BranchSelect v-model="bankForm.payeeBankNo" @change="changeBank"></BranchSelect>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showSetBank = !1">取 消</el-button>
        <el-button type="primary" @click="sureSetBank">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 打款弹窗 -->
    <el-dialog title="打款" :visible.sync="showPayment" width="650px">
      <el-form label-width="100px">
        <el-form-item label="开户行：">{{ currentInfo.bank_info.bank_info }}</el-form-item>
        <!-- <el-form-item label="开户支行："></el-form-item> -->
        <el-form-item label="银行卡号：">{{ currentInfo.bank_info.bank_card }}</el-form-item>
        <el-form-item label="持卡人：">{{ currentInfo.bank_info.bank_name }}</el-form-item>
        <el-form-item label="打款金额：">
          <el-input v-model="paymentForm.remit_amount"></el-input>
        </el-form-item>
        <el-form-item label="打款凭证：">
          <ReadyUploadSource
            :showStyle="{
              width: '150px',
              height: '100px',
            }"
            @getSource="val => (paymentForm.voucher = val.path)"
            :path="paymentForm.voucher"
            @removeThis="() => (paymentForm.voucher = '')"
          ></ReadyUploadSource>
        </el-form-item>
        <el-form-item label="打款备注：">
          <el-input type="textarea" :rows="5" v-model="paymentForm.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showPayment = !1">取 消</el-button>
        <el-button type="primary" @click="comfirmPayment">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 查看打款明细 -->
    <el-dialog title="查看明细" :visible.sync="showDetails" width="880px">
      <el-table :data="detailsInfo.list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="withdraw_no" label="打款单号" align="center"></el-table-column>
        <el-table-column prop="remit_amount" label="打款金额" align="center"></el-table-column>
        <el-table-column prop="bank_name" label="收款人" align="center"></el-table-column>
        <el-table-column prop="remark" label="打款备注" align="center"></el-table-column>
        <el-table-column prop="create_time" label="打款时间" align="center">
          <template v-slot="{ row }">
            {{ getDateformat(row.create_time) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template v-slot="{ row }">
            <el-button @click="viewSchedule(row)" type="text">打款进度</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Paging :total="detailsInfo.total" :page="detailsInfo.page" :pageNum="detailsInfo.rows" @updatePageNum="balanceUpdateData"></Paging>
    </el-dialog>
    <el-dialog title="打款进度" :visible.sync="isPaymentDialog" width="500px">
      <el-form label-width="150px">
        <el-form-item label="提现单号">{{ paymentData.mchTradeNo }}</el-form-item>
        <el-form-item label="提现状态">{{ paymentData.orderStatusLabel }}</el-form-item>
        <el-form-item label="审核状态">{{ paymentData.auditStatusLabel }}</el-form-item>
      </el-form>
    </el-dialog>
    <!-- 审核弹窗 -->
    <el-dialog title="审核" :visible.sync="examineDialog">
      <el-form label-width="170px" :rules="rules">
        <el-form-item label="审核：">
          <el-radio-group v-model="examineInfo.status">
            <el-radio :label="1">通过</el-radio>
            <el-radio :label="-1">驳回</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="自定义组局扣除比例：" v-if="examineInfo.status == 1" required>
          <el-input style="width: 400px" placeholder="请输入0～1之间的小数" v-model="examineInfo.settle_rate"></el-input>
        </el-form-item>
        <el-form-item label="套餐组局扣除比例：" v-if="examineInfo.status == 1" required>
          <el-input style="width: 400px" placeholder="请输入0～1之间的小数" v-model="examineInfo.meal_settle_rate"></el-input>
        </el-form-item>
        <el-form-item label="主理人带队费：" v-if="examineInfo.status == 1" required>
          <el-input style="width: 400px" placeholder="请输入" type="number" v-model="examineInfo.hoster_avg_price"></el-input>
        </el-form-item>
        <el-form-item label="驳回原因：" v-if="examineInfo.status == -1">
          <el-input type="textarea" :rows="5" v-model="examineInfo.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="examineDialog = !1">取 消</el-button>
        <el-button type="primary" @click="sureExamineFn">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑主理人配置 -->
    <el-dialog title="配置" :visible.sync="editConfigDialog">
      <el-form label-width="170px">
        <el-form-item label="主理人：">
          <UserInfo :avatar="editConfigData.avatar" :name="editConfigData.nickname"></UserInfo>
        </el-form-item>
        <el-form-item label="主理人等级：">
          <el-select v-model="editConfigData.level_id" placeholder="请选择">
            <el-option v-for="item in level_id_list" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="自定义组局扣除比例：">
          <el-input placeholder="请输入0～1之间的小数" v-model="editConfigData.settle_rate"></el-input>
        </el-form-item>
        <el-form-item label="套餐组局扣除比例：">
          <el-input placeholder="请输入0～1之间的小数" v-model="editConfigData.meal_settle_rate"></el-input>
        </el-form-item>
        <el-form-item label="主理人带队费：">
          <el-input placeholder="请输入" type="number" v-model="editConfigData.hoster_avg_price"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="examineDialog = !1">取 消</el-button>
        <el-button type="primary" @click="sureEditConfig">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import _ from 'lodash';
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
import { identity_type_hash } from '@/util/util';
import ReadyUploadSource from '@/components/readyUploadSource';
import BranchSelect from '@/components/branchSelect';
import UserInfo from '@/components/userInfo';
export default {
  components: {
    Paging,
    ReadyUploadSource,
    UserInfo,
    BranchSelect,
  },
  data() {
    return {
      identity_type_hash,
      searchForm: {
        page: 1,
        rows: 10,
        create_time: [],
        mobile: '',
        nickname: '',
      },
      list: [],
      total_number: 0,
      showSetBank: !1,
      currentInfo: {
        bank_info: {},
      },
      currentDetailsInfo: {},
      bankForm: {
        bank_name: '',
        bank_card: '',
        payeeBankNo: '',
        bank_info: '',
      },
      showPayment: false,
      paymentForm: {
        remit_amount: '',
        voucher: '',
        remark: '',
      },
      showDetails: false,
      detailsInfo: {
        page: 1,
        rows: 10,
        total: 0,
        list: [],
      },
      paymentData: {},
      isPaymentDialog: false,
      examineDialog: false,
      examineInfo: {
        status: 1,
        remark: '',
        settle_rate: '',
        meal_settle_rate: '',
        hoster_avg_price: '',
      },
      level_id_hash: {
        0: '普通主理人',
        1: '高级主理人',
        2: '官方主理人',
      },
      level_id_list: [
        { id: 0, name: '普通主理人' },
        { id: 1, name: '高级主理人' },
        { id: 2, name: '官方主理人' },
      ],
      editConfigDialog: false,
      editConfigData: {},
    };
  },
  created() {
    this.getList();
  },
  methods: {
    sureEditConfig() {
      this.$axios
        .post(this.$api.yiyun.editSettleRate, {
          id: this.editConfigData.id,
          level_id: this.editConfigData.level_id,
          meal_settle_rate: this.editConfigData.meal_settle_rate,
          settle_rate: this.editConfigData.settle_rate,
          hoster_avg_price: this.editConfigData.hoster_avg_price,
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('修改成功');
            this.getList();
            this.editConfigDialog = false;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    showHosterConfig(data) {
      this.editConfigData = JSON.parse(JSON.stringify(data));
      this.editConfigDialog = true;
    },
    sureExamineFn() {
      let obj = {
        id: this.currentDetailsInfo.id,
        status: this.examineInfo.status,
      };
      if (this.examineInfo.status == -1) {
        obj.remark = this.examineInfo.remark;
      }
      if (this.examineInfo.status == 1) {
        if (parseFloat(this.examineInfo.settle_rate) <= 0 || parseFloat(this.examineInfo.settle_rate) >= 1) {
          this.$message.warning('请合理输入自定义组局结算比例');
          return;
        }
        if (parseFloat(this.examineInfo.meal_settle_rate) <= 0 || parseFloat(this.examineInfo.meal_settle_rate) >= 1) {
          this.$message.warning('请合理输入套餐组局结算比例');
          return;
        }
        if (this.examineInfo.hoster_avg_price === '') {
          this.$message.warning('请输入主理人带队费');
          return;
        }
        obj.settle_rate = this.examineInfo.settle_rate;
        obj.meal_settle_rate = this.examineInfo.meal_settle_rate;
        obj.hoster_avg_price = this.examineInfo.hoster_avg_price;
      }
      this.$axios.post(this.$api.yiyun.hosterExamine, obj).then(res => {
        if (res.code == 0) {
          let str = this.examineInfo.status ? '已通过' : '已驳回';
          this.$message.success(str);
          this.getList();
          this.examineDialog = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    toExamineFn(data) {
      this.currentDetailsInfo = data;
      this.examineInfo = {
        status: 1,
        remark: '',
      };
      this.examineDialog = true;
    },
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getList();
    },
    clearSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        create_time: [],
        mobile: '',
        nickname: '',
      };
      this.getList();
    },
    getList(style) {
      if (style) {
        this.searchForm.page = 1;
      }
      let obj = {
        page: this.searchForm.page,
        rows: this.searchForm.rows,
      };
      if (this.searchForm.mobile) obj.mobile = this.searchForm.mobile;
      if (this.searchForm.nickname) obj.nickname = this.searchForm.nickname;
      if (this.searchForm.create_time?.length) {
        obj.create_time = [];
        obj.create_time[0] = this.searchForm.create_time[0] / 1000;
        obj.create_time[1] = this.searchForm.create_time[1] / 1000;
        if (obj.create_time[0] === obj.create_time[1]) {
          obj.create_time[1] = obj.create_time[0] + 60 * 60 * 24 - 1;
        }
      }
      this.$axios.post(this.$api.yiyun.hosterList, obj).then(res => {
        if (res.code == 0) {
          this.list = res.result.list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    operateClickFn(data, mode) {
      if (!data.is_bank && mode != 1) {
        this.$message.warning('请先设置银行卡');
        this.operateClickFn(data, 1);
        return;
      }
      data.bank_info = data.bank_info || {};
      this.currentInfo = data;
      if (mode == 1) {
        this.bankForm = {
          bank_name: '',
          bank_card: '',
          payeeBankNo: '',
          bank_info: '',
        };
        this.showSetBank = true;
      }
      if (mode == 2) {
        this.paymentForm = {
          remit_amount: '',
          voucher: '',
          remark: '',
        };
        this.showPayment = true;
      }
      if (mode == 3) this.getDetailList(1);
    },
    // 确认设置银行卡
    sureSetBank() {
      if (!this.bankForm.bank_name) {
        this.$message.warning('请填写持卡人姓名');
        return;
      }
      if (!this.bankForm.bank_card) {
        this.$message.warning('请填写银行卡号');
        return;
      }
      if (!this.bankForm.payeeBankNo) {
        this.$message.warning('请选择开户支行');
        return;
      }
      this.bankForm.id = this.currentInfo.id;
      this.$axios.post(this.$api.yiyun.setBankInfo, this.bankForm).then(res => {
        if (res.code == 0) {
          this.$message.success('设置成功');
          this.getList();
          this.showSetBank = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 确认打款
    comfirmPayment() {
      if (!this.paymentForm.remit_amount) {
        this.$message.warning('请填写打款金额');
        return;
      }
      if (!this.paymentForm.voucher) {
        this.$message.warning('请上传打款凭证');
        return;
      }
      if (!this.paymentForm.remark) {
        this.$message.warning('请填写打款备注');
        return;
      }
      this.paymentForm.id = this.currentInfo.id;
      this.$axios.post(this.$api.yiyun.hosterTransterStore, this.paymentForm).then(res => {
        if (res.code == 0) {
          this.$message.success('打款成功');
          this.showPayment = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 查看明细
    getDetailList(style) {
      if (style) {
        this.detailsInfo.page = 1;
      }
      let obj = {
        id: this.currentInfo.id,
        page: this.detailsInfo.page,
        rows: this.detailsInfo.rows,
      };
      this.$axios.post(this.$api.yiyun.withDrawList, obj).then(res => {
        if (res.code == 0) {
          this.detailsInfo.list = res.result.list;
          this.detailsInfo.total = res.result.total_number;
          this.showDetails = true;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    changeBank(data) {
      this.bankForm.bank_info = data.bank_name;
    },
    viewSchedule(data) {
      this.currentDetailsInfo = data;
      this.$axios
        .post(this.$api.yiyun.hosterTransterqueryOrder, {
          id: data.id,
        })
        .then(res => {
          if (res.code == 0) {
            // data.orderStatus        订单状态        0处理中 1处理成功 2处理失败 3退票 4成功处理
            // data.auditStatus        审核状态        0审核中 1审核通过 2驳回
            let orderStatusHash = {
              0: '处理中',
              1: '处理成功',
              2: '处理失败',
              3: '退票',
              4: '成功处理',
            };
            let auditStatusHash = {
              0: '审核中 ',
              1: '审核通过',
              2: '驳回',
            };
            let data = res.result.data;
            data.orderStatusLabel = orderStatusHash[data.orderStatus];
            data.auditStatusLabel = auditStatusHash[data.auditStatus];
            this.paymentData = data;
            this.isPaymentDialog = true;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .total-num {
    display: flex;
    font-weight: bold;
    margin: 20px 0;
    div {
      margin-right: 40px;
    }
  }
}
</style>
